import React from 'react';
import '../styles/project-components/projects.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function WorldEater() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
    };

    return (
        <div className='wrapper'>
            <section id='world-eater-ascent'>
                <div className='header'>
                    <div className='logo'>
                        <img src="../images/world-eater/logo_glow.png" alt="world eater" />
                    </div>
                </div>
            </section>
            <section id='world-eater-intro'>
                <div className='intro'>
                    <h1>Overview</h1>
                    <p className='subtitle'>World Eater is about an epic bossfight against a giant, world eating worm!</p>
                </div>
            </section>
            <section id="world-eater-involvement">
                <div className="involvement">
                    <h1>My Involvement</h1>
                    <p className="subtitle">These are the most important aspects I worked on.</p>
                    <div className="card-wrapper">
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Game Design"
                                descriptions={[
                                    'Wrote the base game design document and its intricacies',
                                    'Designed the bossfight, including phases and attacks',
                                    'Designed the world and the player character',
                                    'Wrote the backstory and designed the setting',
                                    'Currently working on expanding the game design document',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Development"
                                descriptions={[
                                    'Created an entire gravity system from scratch',
                                    'Implemented a boss state machine AI based on probability',
                                    'Designed and developed a shockwave system, which shapes objects around the planetary surface',
                                    'Made basic player controls, camera controls and movement',
                                    'Currently working on further expanding the boss state machine and its probability system',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Art"
                                descriptions={[
                                    'Designed the demo with basic shapes and colors',
                                    'Contributing to figuring out the art direction and style',
                                    'Currently working on creating logos and other publication art assets',
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id='world-eater-design'>
                <div className='project-design'>
                    <h1>Project Design</h1>
                </div>
            </section>
            <section id='world-eater-images'>
                <div>
                    <h1>Art-Reel</h1>
                </div>
                <div className='images'>
                    <Slider {...settings}>
                        {/* todo: add */}
                        
                    </Slider>
                </div>
            </section>
            <section id='world-eater-demo'>
                <iframe className='demo' width="560" height="315" src="https://www.youtube.com/embed/U_FCgnhmWtU?si=FTYU37MIN-a7djo8" title="YouTube video player"
                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </section>
        </div>
    );
};

function ProjectImage(props: { src: string, alt: string }) {

    return (
        <a className="image-card">
            <img src={props.src} alt={props.alt} />
        </a>
    );
}

export function ProjectInvolvementCard(props: { title: string, descriptions: string[] }) {
    return (
        <div className="project-inv-card">
            <h2>{props.title}</h2>
            <ul>
                {props.descriptions.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ul>
        </div>
    );
}

export default WorldEater;
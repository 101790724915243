import React from 'react';
import '../styles/project-components/projects.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function SpaceTrashers() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
    };

    return (
        <div className='wrapper'>
            <section id='spacetrashers-ascent'>
                <div className='header'>
                    <div className='logo'>
                        <img src="../images/space-trashers/logo_glow.png" alt="space trashers" />
                    </div>
                </div>
            </section>
            <section id='spacetrashers-intro'>
                <div className='intro'>
                    <h1>Overview</h1>
                    <p className='subtitle'>Space Trashers is a fresh remake of the classic arcade game Space Invaders.</p>
                    {/* todo: add timeframe, team members, roles?, desc */}
                </div>
            </section>
            <section id="spacetrashers-involvement">
                <div className="involvement">
                    <h1>My Involvement</h1>
                    <p className="subtitle">These are the most important aspects I worked on.</p>
                    <div className="card-wrapper">
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Game Design"
                                descriptions={[
                                    'Designed setting, enemies, bosses and their meaning within the game',
                                    'Made decisions on game mechanics, such as power-ups and weapons',
                                    'Balanced difficulty and progression based on user feedback',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Development"
                                descriptions={[
                                    'Implemented player movement and shooting mechanics',
                                    'Applied smoothing to player and boss movement',
                                    'Created enemy movement patterns and simplistic boss AI',
                                    'Created a simple wave system for enemy spawning',
                                    'Implemented a simple randomized shooting pattern for enemies',
                                    'Designed and created a shop system for the player to purchase power-ups',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Art"
                                descriptions={[
                                    'Designed and created all sprites for the game (except for backgrounds)',
                                    'Made animations for the player and the bullets',
                                    'Created simplistic UI elements using HTML and CSS',
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id='spacetrashers-design'>
                <div className='project-design'>
                    <h1>Project Design</h1>
                </div>
            </section>
            <section id='spacetrashers-images'>
                <div>
                    <h1>Art-Reel</h1>
                </div>
                <div className='images'>
                    <Slider {...settings}>
                        {/* todo change these */}
                        <ProjectImage src='images/sinful-ascent/intro.gif' alt='intro' />
                        <ProjectImage src='images/sinful-ascent/lever.gif' alt='lever' />
                    </Slider>
                </div>
            </section>
        </div>
    );
};

function ProjectImage(props: { src: string, alt: string }) {

    return (
        <a className="image-card">
            <img src={props.src} alt={props.alt} />
        </a>
    );
}

export function ProjectInvolvementCard(props: { title: string, descriptions: string[] }) {
    return (
        <div className="project-inv-card">
            <h2>{props.title}</h2>
            <ul>
                {props.descriptions.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ul>
        </div>
    );
}

export default SpaceTrashers;
import React from 'react';
import '../styles/project-components/projects.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function FlappyClone() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
    };

    return (
        <div className='wrapper'>
            <section id='flappy-clone'>
                <div className='header'>
                    <div className='logo'>
                        <img src="../images/flappy-clone/logo_glow.png" alt="Flappy Clone" />
                    </div>
                </div>
            </section>
            <section id='flappy-intro'>
                <div className='intro'>
                    <h1>Overview</h1>
                    <p className='subtitle'>(NOT) Your Average Flappy Clone is a Flappy Bird game with a cool new spin into another dimension!</p>
                </div>
            </section>
            <section id="flappy-involvement">
                <div className="involvement">
                    <h1>My Involvement</h1>
                    <p className="subtitle">These are the most important aspects I worked on.</p>
                    <div className="card-wrapper">
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Game Design"
                                descriptions={[
                                    'Used playtesting to further improve game mechanics, including game-feel, UI, and level design',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Development"
                                descriptions={[
                                    'Designed and wrote a map loader based on tilesets with multi-layered rendering',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Art"
                                descriptions={[
                                    'Designed and created all sprites and animations',
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id='sinful-design'>
                <div className='project-design'>
                    <h1>Project Design</h1>

                </div>
            </section>
            <section id='sinful-images'>
                <div>
                    <h1>Art-Reel</h1>
                </div>
                <div className='images'>
                    <Slider {...settings}>
                        <ProjectImage src='images/flappy-clone/screenshot.png' alt='screenshot' />
                        <ProjectImage src='images/flappy-clone/screenshot1.png' alt='screenshot1' />
                    </Slider>
                </div>
            </section>
            <section id='sinful-links'>
            <iframe className='itch-widget' frameBorder="0" src="https://itch.io/embed/2965758?bg_color=020407&amp;fg_color=ffffff&amp;link_color=aa59f3&amp;border_color=343639"
            width="552" height="167"><a href="https://pprmsk.itch.io/not-your-average-flappy-clone">(NOT) Your Average Flappy Clone by papermask</a></iframe>
            </section>
        </div>
    );
};

function ProjectImage(props: { src: string, alt: string }) {

    return (
        <a className="image-card">
            <img src={props.src} alt={props.alt} />
        </a>
    );
}

export function ProjectInvolvementCard(props: { title: string, descriptions: string[] }) {
    return (
        <div className="project-inv-card">
            <h2>{props.title}</h2>
            <ul>
                {props.descriptions.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ul>
        </div>
    );
}

export default FlappyClone;
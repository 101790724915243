import React from 'react';
import '../styles/project-components/projects.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function Horimono() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
    };

    return (
        <div className='wrapper'>
            <section id='horimono-ascent'>
                <div className='header'>
                    <div className='logo'>
                        <img src="../images/horimono/logo_glow.png" alt="HORIMONO" />
                    </div>
                </div>
            </section>
            <section id='horimono-intro'>
                <div className='intro'>
                    <h1>Overview</h1>
                    <p className='subtitle'>HORIMONO is a 2D metroidvania action platformer set hundreds of years ago in rural Japan.</p>
                </div>
            </section>
            <section id="horimono-involvement">
                <div className="involvement">
                    <h1>My Involvement (IN DEVELOPMENT)</h1>
                    <p className="subtitle">These aspects I am currently working on.</p>
                    <div className="card-wrapper">
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Game Design"
                                descriptions={[
                                    'Finishing up the game design document and its intricacies',
                                    'Deciding on art style, resolution and theming',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Development"
                                descriptions={[
                                    'Project has been setup in Unity using basic shapes',
                                    'Basic controls and movement have been implemented',
                                    'Currently working on extending movement mechanics',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Art"
                                descriptions={[
                                    'Moodboard has been created and is being updated',
                                    'Currently working on character design and general art direction',
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id='horimono-design'>
                <div className='project-design'>
                    <h1>Project Design</h1>
                </div>
            </section>
        </div>
    );
};

function ProjectImage(props: { src: string, alt: string }) {

    return (
        <a className="image-card">
            <img src={props.src} alt={props.alt} />
        </a>
    );
}

export function ProjectInvolvementCard(props: { title: string, descriptions: string[] }) {
    return (
        <div className="project-inv-card">
            <h2>{props.title}</h2>
            <ul>
                {props.descriptions.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ul>
        </div>
    );
}

export default Horimono;
import React from 'react';
import '../styles/project-components/projects.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function SinfulAscent() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
    };

    return (
        <div className='wrapper'>
            <section id='sinful-ascent'>
                <div className='header'>
                    <div className='logo'>
                        <img src="../images/sinful-ascent/logo_glow.png" alt="Sinful Ascent" />
                    </div>
                </div>
            </section>
            <section id='sinful-intro'>
                <div className='intro'>
                    <h1>Overview</h1>
                    <p className='subtitle'>Sinful Ascent is a 2D top-down action shooter game where you play as a demon climbing the seven layers of hell.</p>
                </div>
            </section>
            <section id="sinful-involvement">
                <div className="involvement">
                    <h1>My Involvement</h1>
                    <p className="subtitle">These are the most important aspects I worked on.</p>
                    <div className="card-wrapper">
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Game Design"
                                descriptions={[
                                    'Used playtesting to further improve game mechanics, including game-feel, UI, and level design',
                                    'Designed all levels, layouts and layers in the Tiled editor',
                                    'Designed all boss fights and enemy encounters',
                                    'Worked on difficulty balancing, such as damage values, enemy & player health',
                                    'Wrote the backstory and designed the setting',
                                    'Fully created and developed the game design document',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Development"
                                descriptions={[
                                    'Designed and wrote a map loader based on tilesets with multi-layered rendering',
                                    'Implemented reusable and modular environmental hazards, including traps, spikes, and pits',
                                    'Used enemy AI to create interesting enemy behaviors',
                                    'Developed player controls and movement (dodge roll, walking, interaction)',
                                    'Wrote all level logic and progression logic (such as doors and levers)',
                                    'Created collision based on tilemap tile indexes',
                                ]}
                            />
                        </div>
                        <div className="inv-card">
                            <ProjectInvolvementCard
                                title="Art"
                                descriptions={[
                                    'Designed and created all sprites and animations',
                                    'Implemented beginning cutscene and transitions between levels',
                                    'Designed and implemented the UI, such as health bars and a custom mouse cursor',
                                    "Created two versions of the game's logo and made the title screen",
                                    'Further built upon existing tilesets for level creation'
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id='sinful-design'>
                <div className='project-design'>
                    <h1>Project Design</h1>
                </div>
            </section>
            <section id='sinful-images'>
                <div>
                    <h1>Art-Reel</h1>
                </div>
                <div className='images'>
                    <Slider {...settings}>
                        <ProjectImage src='images/sinful-ascent/intro.gif' alt='intro' />
                        <ProjectImage src='images/sinful-ascent/lever.gif' alt='lever' />
                        <ProjectImage src='images/sinful-ascent/playerIdle.gif' alt='playerIdle' />
                        <ProjectImage src='images/sinful-ascent/playerFalling.gif' alt='playerFalling' />
                        <ProjectImage src='images/sinful-ascent/dummyAnim.gif' alt='dummyAnim' />
                        <ProjectImage src='images/sinful-ascent/batAnim.gif' alt='batAnim' />
                        <ProjectImage src='images/sinful-ascent/skulls.gif' alt='skulls' />
                        <ProjectImage src='images/sinful-ascent/slime.png' alt='slime' />
                        <ProjectImage src='images/sinful-ascent/sloth.png' alt='sloth' />
                        <ProjectImage src='images/sinful-ascent/pride.png' alt='pride' />
                        <ProjectImage src='images/sinful-ascent/level.png' alt='level' />
                        <ProjectImage src='images/sinful-ascent/bosslevel.png' alt='boss level' />
                    </Slider>
                </div>
            </section>
            <section id='sinful-links'>
                <iframe className='itch-widget' frameBorder="0" src="https://itch.io/embed/2720680?bg_color=020407&amp;fg_color=ffffff&amp;link_color=C92F2F&amp;border_width=0"
                    width="552" height="167"><a href="https://5bytegames.itch.io/sinful-ascent">Sinful Ascent by 5ByteGames</a></iframe>
            </section>
        </div>
    );
};

function ProjectImage(props: { src: string, alt: string }) {

    return (
        <a className="image-card">
            <img src={props.src} alt={props.alt} />
        </a>
    );
}

export function ProjectInvolvementCard(props: { title: string, descriptions: string[] }) {
    return (
        <div className="project-inv-card">
            <h2>{props.title}</h2>
            <ul>
                {props.descriptions.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ul>
        </div>
    );
}

export default SinfulAscent;
import React, { useState } from 'react';
import { Bio } from '../data/constants';
import '../styles/components/about.css';

export function About() {
    // State to manage the current name displayed
    const [currentName, setCurrentName] = useState(Bio.name[0]);

    // State to manage the class names
    const [classNames, setClassNames] = useState('about-title');

    // Handler for mouse enter
    const handleMouseEnter = () => {
        setCurrentName(Bio.name[1]);
        setClassNames('about-title title-hover');
    };

    // Handler for mouse leave
    const handleMouseLeave = () => {
        setCurrentName(Bio.name[0]);
        setClassNames('about-title');
    };

    return (
        <section id='about'>
            <div className='page-wrapper'>
                <div className='about-container'>
                    <div className='about-intro'>
                        <div
                            className='title-container'
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <h2 className={classNames}>{currentName}</h2>
                        </div>
                        <h3>Student & Indie Game Developer</h3>
                        <p className='about-desc'>{Bio.description}</p>
                        <button
                            className='resume-button'
                            onClick={() => window.open('resume.pdf')}
                        >
                            View Resume
                        </button>
                    </div>
                    <div className='image-container'>
                        <div className='about-img'>
                            <img src={Bio.image} alt='about pf' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
